import {
    Injectable
} from '@angular/core';
import {
    WebView
} from '@ionic-native/ionic-webview/ngx';
import {
    Device
} from '@ionic-native/device/ngx';
import {
    SplashScreen
} from '@ionic-native/splash-screen/ngx';
import {
    StatusBar
} from '@ionic-native/status-bar/ngx';
import {
    Keyboard
} from '@ionic-native/keyboard/ngx';
import {
    InAppBrowser
} from '@ionic-native/in-app-browser/ngx';
import {
    File
} from '@ionic-native/file/ngx';
import {
    Push
} from '@ionic-native/push/ngx';
import {
    BarcodeScanner
} from '@ionic-native/barcode-scanner/ngx';
import {
    Contacts
} from '@ionic-native/contacts/ngx';
import {
    Camera
} from '@ionic-native/camera/ngx';
import {
    ScreenOrientation
} from '@ionic-native/screen-orientation/ngx';
import {
    Geolocation
} from '@ionic-native/geolocation/ngx';
import {
    SpeechRecognition
} from '@ionic-native/speech-recognition/ngx';
import {
    Media
} from '@ionic-native/media/ngx';
import {
    Network
} from '@ionic-native/network/ngx';
import {
    SecureStorage,
    SecureStorageObject
} from '@ionic-native/secure-storage/ngx';
import {
    Dialogs
} from '@ionic-native/dialogs/ngx';
import {
    Globalization
} from '@ionic-native/globalization/ngx';
@Injectable()
export class ApperyioNativeHelperService {
    public audioinput;
    constructor(
        public webView: WebView, public device: Device, public splashScreen: SplashScreen, public statusBar: StatusBar, public keyboard: Keyboard, public inAppBrowser: InAppBrowser, public file: File, public push: Push, public barcodeScanner: BarcodeScanner, public contacts: Contacts, public camera: Camera, public screenOrientation: ScreenOrientation, public geolocation: Geolocation, public speechRecognition: SpeechRecognition, public media: Media, public network: Network, public secureStorage: SecureStorage, public dialogs: Dialogs, public globalization: Globalization
    ) {
        this.audioinput = (window as any).audioinput;
    }
};