import {
    NgModule
} from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';
const routes: Routes = [{
        path: '',
        redirectTo: 'tabs',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: './Home/Home.module#HomePageModule',
    },
    {
        path: 'tabs',
        loadChildren: './Tabs/Tabs.module#TabsPageModule',
    },
    {
        path: 'info',
        loadChildren: './Info/Info.module#InfoPageModule',
    },
    {
        path: 'livechat',
        loadChildren: './LiveChat/LiveChat.module#LiveChatPageModule',
    },
    {
        path: 'menu',
        loadChildren: './Menu/Menu.module#MenuPageModule',
    },
    {
        path: 'account_services',
        loadChildren: './Account_Services/Account_Services.module#Account_ServicesPageModule',
    },
    {
        path: 'during_my_stay',
        loadChildren: './During_My_Stay/During_My_Stay.module#During_My_StayPageModule',
    },
    {
        path: 'surveys',
        loadChildren: './Surveys/Surveys.module#SurveysPageModule',
    },
    {
        path: 'lifestyle_smiles',
        loadChildren: './Lifestyle_Smiles/Lifestyle_Smiles.module#Lifestyle_SmilesPageModule',
    },
    {
        path: 'cc_website',
        loadChildren: './CC_Website/CC_Website.module#CC_WebsitePageModule',
    },
    {
        path: 'golf_carts',
        loadChildren: './Golf_Carts/Golf_Carts.module#Golf_CartsPageModule',
    },
    {
        path: 'news',
        loadChildren: './News/News.module#NewsPageModule',
    },
    {
        path: 'villabreakfastprebooking',
        loadChildren: './VillaBreakfastPreBooking/VillaBreakfastPreBooking.module#VillaBreakfastPreBookingPageModule',
    },
    {
        path: 'beforearrive',
        loadChildren: './BeforeArrive/BeforeArrive.module#BeforeArrivePageModule',
    },
    {
        path: 'dinnerdrinksprebooking',
        loadChildren: './DinnerDrinksPrebooking/DinnerDrinksPrebooking.module#DinnerDrinksPrebookingPageModule',
    },
    {
        path: 'airporttransfers',
        loadChildren: './AirportTransfers/AirportTransfers.module#AirportTransfersPageModule',
    },
    {
        path: 'prepaymentvoucher',
        loadChildren: './PrePaymentVoucher/PrePaymentVoucher.module#PrePaymentVoucherPageModule',
    },
    {
        path: 'submit_payment_in_advance',
        loadChildren: './Submit_Payment_In_Advance/Submit_Payment_In_Advance.module#Submit_Payment_In_AdvancePageModule',
    },
    {
        path: 'creditcardauthorization',
        loadChildren: './CreditCardAuthorization/CreditCardAuthorization.module#CreditCardAuthorizationPageModule',
    },
    {
        path: 'increasemonthlypayment',
        loadChildren: './IncreaseMonthlyPayment/IncreaseMonthlyPayment.module#IncreaseMonthlyPaymentPageModule',
    },
    {
        path: 'automaticmonthlypayments',
        loadChildren: './AutomaticMonthlyPayments/AutomaticMonthlyPayments.module#AutomaticMonthlyPaymentsPageModule',
    },
    {
        path: 'updatepaymentmethod',
        loadChildren: './UpdatePaymentMethod/UpdatePaymentMethod.module#UpdatePaymentMethodPageModule',
    },
    {
        path: 'pay_balance_in_full',
        loadChildren: './Pay_Balance_In_Full/Pay_Balance_In_Full.module#Pay_Balance_In_FullPageModule',
    },
    {
        path: 'reservations',
        loadChildren: './Reservations/Reservations.module#ReservationsPageModule',
    },
    {
        path: 'intravel',
        loadChildren: './Intravel/Intravel.module#IntravelPageModule',
    },
    {
        path: 'forma',
        loadChildren: './FormA/FormA.module#FormAPageModule',
    },
    {
        path: 'formb',
        loadChildren: './FormB/FormB.module#FormBPageModule',
    },
    {
        path: 'formc',
        loadChildren: './FormC/FormC.module#FormCPageModule',
    },
    {
        path: 'satisfaction_survey',
        loadChildren: './Satisfaction_Survey/Satisfaction_Survey.module#Satisfaction_SurveyPageModule',
    },
    {
        path: 'favorite_foods_survey',
        loadChildren: './Favorite_Foods_Survey/Favorite_Foods_Survey.module#Favorite_Foods_SurveyPageModule',
    },
    {
        path: 'resort_map',
        loadChildren: './Resort_Map/Resort_Map.module#Resort_MapPageModule',
    },
    {
        path: 'pillow_menu',
        loadChildren: './Pillow_Menu/Pillow_Menu.module#Pillow_MenuPageModule',
    },
    {
        path: 'yin_yang_spa',
        loadChildren: './Yin_Yang_Spa/Yin_Yang_Spa.module#Yin_Yang_SpaPageModule',
    },
    {
        path: 'babysitting_service_request',
        loadChildren: './Babysitting_Service_Request/Babysitting_Service_Request.module#Babysitting_Service_RequestPageModule',
    },
    {
        path: 'excursions',
        loadChildren: './Excursions/Excursions.module#ExcursionsPageModule',
    },
    {
        path: 'kids_club_program',
        loadChildren: './Kids_Club_Program/Kids_Club_Program.module#Kids_Club_ProgramPageModule',
    },
    {
        path: 'ask_my_concierge_butler',
        loadChildren: './Ask_My_Concierge_Butler/Ask_My_Concierge_Butler.module#Ask_My_Concierge_ButlerPageModule',
    },
    {
        path: 'departure_transportation',
        loadChildren: './Departure_Transportation/Departure_Transportation.module#Departure_TransportationPageModule',
    },
];
@NgModule({
    imports: [RouterModule.forRoot(
        routes, {
            enableTracing: false,
            useHash: true
        }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {}