export const constants = {
    /**
     * Settings
     */
    Settings: {}
};
export const routes = {
    "Home": "home",
    "Tabs": "tabs",
    "Info": "info",
    "LiveChat": "livechat",
    "Menu": "menu",
    "Account_Services": "account_services",
    "During_My_Stay": "during_my_stay",
    "Surveys": "surveys",
    "Lifestyle_Smiles": "lifestyle_smiles",
    "CC_Website": "cc_website",
    "Golf_Carts": "golf_carts",
    "News": "news",
    "VillaBreakfastPreBooking": "villabreakfastprebooking",
    "BeforeArrive": "beforearrive",
    "DinnerDrinksPrebooking": "dinnerdrinksprebooking",
    "AirportTransfers": "airporttransfers",
    "PrePaymentVoucher": "prepaymentvoucher",
    "Submit_Payment_In_Advance": "submit_payment_in_advance",
    "CreditCardAuthorization": "creditcardauthorization",
    "IncreaseMonthlyPayment": "increasemonthlypayment",
    "AutomaticMonthlyPayments": "automaticmonthlypayments",
    "UpdatePaymentMethod": "updatepaymentmethod",
    "Pay_Balance_In_Full": "pay_balance_in_full",
    "Reservations": "reservations",
    "Intravel": "intravel",
    "FormA": "forma",
    "FormB": "formb",
    "FormC": "formc",
    "Satisfaction_Survey": "satisfaction_survey",
    "Favorite_Foods_Survey": "favorite_foods_survey",
    "Resort_Map": "resort_map",
    "Pillow_Menu": "pillow_menu",
    "Yin_Yang_Spa": "yin_yang_spa",
    "Babysitting_Service_Request": "babysitting_service_request",
    "Excursions": "excursions",
    "Kids_Club_Program": "kids_club_program",
    "Ask_My_Concierge_Butler": "ask_my_concierge_butler",
    "Departure_Transportation": "departure_transportation",
};
export const pushSettings = {
    appID: 'db3ca821-a4af-4535-9747-7a2a18e6060d',
    baseUrl: 'https://api.appery.io/rest/push/reg',
    baseSendUrl: 'https://api.appery.io/rest/push/msg',
    initOptions: {}
};
export const IGNORED_VALUE = Symbol.for("AIO_REST_IGNORED_VALUE");